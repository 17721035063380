import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { CanView } from 'components/Can';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import RolesViewPage from 'pages/roles/View';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import useUtils from 'hooks/useUtils';

import useListPage from 'hooks/useListPage';
import useFilters from 'hooks/useFilters';
const UsersListPage = (props) => {
		const auth = useAuth();
	const app = useApp();
	const utils = useUtils();
	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageFilterController = useFilters(filterSchema);
	const pageController = useListPage(props, pageFilterController);
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue } = pageFilterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } =  pagination;
	function ActionButton(data){
		const items = [
		{
			label: "View",
			command: (event) => { app.navigate(`/users/view/${data.userid}`) },
			icon: "pi pi-eye",
			visible: () => auth.canView('users/view')
		},
		{
			label: "Edit",
			command: (event) => { app.navigate(`/users/edit/${data.userid}`) },
			icon: "pi pi-pencil",
			visible: () => auth.canView('users/edit')
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.userid) },
			icon: "pi pi-minus-circle",
			visible: () => auth.canView('users/delete')
		}
	]
	.filter((item) => {
		if(item.visible){
			return item.visible()
		}
		return true;
	});
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function UseridTemplate(data){
		if(data){
			return (
				<Link to={`/users/view/${data.userid}`}> { data.userid }</Link>
			);
		}
	}
	function UseremailTemplate(data){
		if(data){
			return (
					<a className="p-button-text" href={'mailto:' + data.useremail}>{ data.useremail }</a>
			);
		}
	}
	function UserRoleIdTemplate(data){
		if(data){
			return (
				<>{data.user_role_id && <Button className="p-button-text" icon="pi pi-eye" label="Roles" onClick={() => app.openPageDialog(<RolesViewPage isSubPage apiPath={`/roles/view/${data.user_role_id}`} />, {closeBtn: true })} /> }</>
			);
		}
	}
	function DateCreatedTemplate(data){
		if(data){
			return (
				<>{data.date_created && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(data.date_created)} tooltip={utils.humanDatetime(data.date_created)} />}</>
			);
		}
	}
	function DateUpdatedTemplate(data){
		if(data){
			return (
				<>{data.date_updated && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(data.date_updated)} tooltip={utils.humanDatetime(data.date_updated)} />}</>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">Cargando...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					ningún record fue encontrado
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Eliminar seleccionado"/>
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">Archivos <b>{ recordsPosition } de { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap justify-content-between">
	<CanView pagePath="users/delete">
		<MultiDelete />
	</CanView>
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main className="main-page">
{ (props.showHeader) && 
<section className="page-section mb-3" >
    <div className="container-fluid">
        <div className="grid justify-content-between align-items-center">
            <div className="col " >
                <Title title="Users"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-sm text-500"      separator={false} />
            </div>
            <div className="col-12 md:col-3 " >
                <CanView pagePath="/users/add">
                    <Link to={`/users/add`}>
                        <Button label="Agregar nuevo" icon="pi pi-plus" type="button" className="p-button w-full bg-primary "  />
                        </Link>
                    </CanView>
                </div>
                <div className="col-12 md:col-5 lg:col-4 " >
                    <span className="p-input-icon-left w-full">
                    <i className="pi pi-search" />
                    <InputText placeholder="Buscar" className="w-full" value={filters.search.value}  onChange={(e) => setFilterValue('search', e.target.value)} />
                    </span>
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container-fluid">
            <div className="grid ">
                <div className="col comp-grid" >
                    <FilterTags filterController={pageFilterController} />
                    <div >
                        <PageBreadcrumbs />
                        <div className="page-records">
                            <DataTable 
                                lazy={true} 
                                loading={loading} 
                                selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                                value={records} 
                                dataKey="userid" 
                                sortField={sortBy} 
                                sortOrder={sortOrder} 
                                onSort={onSort} 
                                stripedRows
                                resizableColumns columnResizeMode="fit"
                                className=" p-datatable-sm" 
                                showGridlines={false} rowHover={true} 
                                responsiveLayout="stack" 
                                emptyMessage={<EmptyRecordMessage />} 
                                >
                                {/*PageComponentStart*/}
                                <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                                <Column  field="userid" header="Userid" body={UseridTemplate}  ></Column>
                                <Column  field="username" header="Username"   ></Column>
                                <Column  field="useremail" header="Useremail" body={UseremailTemplate}  ></Column>
                                <Column  field="user_role_id" header="User Role Id" body={UserRoleIdTemplate}  ></Column>
                                <Column  field="date_created" header="Date Created" body={DateCreatedTemplate}  ></Column>
                                <Column  field="date_updated" header="Date Updated" body={DateUpdatedTemplate}  ></Column>
                                <Column headerStyle={{width: '3em'}} headerClass="text-center" body={ActionButton}></Column>
                                {/*PageComponentEnd*/}
                            </DataTable>
                        </div>
                        <PageLoading />
                        <PageFooter />
                    </div>
                </div>
            </div>
        </div>
    </section>
    </main>
	);
}
UsersListPage.defaultProps = {
	primaryKey: 'userid',
	pageName: 'users',
	apiPath: 'users/index',
	routeName: 'userslist',
	msgBeforeDelete: "¿Seguro que quieres borrar este registro?",
	msgTitle: 'Delete Record',
	msgAfterDelete: "Grabar eliminado con éxito",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default UsersListPage;
