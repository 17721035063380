import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import Audits from 'pages/audits/index';
import Permissions from 'pages/permissions/index';
import Producto from 'pages/producto/index';
import Roles from 'pages/roles/index';
import Users from 'pages/users/index';
import Account from 'pages/account/index';
import ForgotPasswordPage from 'pages/index/ForgotPassword';
import ResetPasswordPage from 'pages/index/ResetPassword';
import ResetPasswordCompletedPage from 'pages/index/ResetPasswordCompleted';
import RegisterPage from 'pages/index/RegisterPage';
import HomePage from './pages/home/HomePage';
import Forbidden from './pages/errors/Forbidden';
import PageNotFound from './pages/errors/PageNotFound';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<HomePage />} />
				<Route path="/audits/*" element={<Audits />} />
				<Route path="/permissions/*" element={<Permissions />} />
				<Route path="/producto/*" element={<Producto />} />
				<Route path="/roles/*" element={<Roles />} />
				<Route path="/users/*" element={<Users />} />
				<Route path="/account/*" element={<Account />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
			<Route path="/" element={<DefaultPage />} />
			<Route path="/index/register" element={<RegisterPage />} />
			<Route path="/index/forgotpassword" element={<ForgotPasswordPage />} />
			<Route path="/index/resetpassword" element={<ResetPasswordPage />} />
			<Route path="/index/resetpassword_completed" element={<ResetPasswordCompletedPage />} />
				<Route path="/error/forbidden" element={<Forbidden />} />
				<Route path="*" element={<PageNotFound />} />
			</Route>
		</Routes>
	);
}
export default App;
