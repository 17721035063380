import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useUtils from 'hooks/useUtils';

import useViewPage from 'hooks/useViewPage';
const AuditsViewPage = (props) => {
		const app = useApp();
	const utils = useUtils();
	const pageController = useViewPage(props);
	const { item, pageReady, loading, apiRequestError } = pageController;
	function ActionButton(data){
		const items = [
		{
			label: "Edit",
			command: (event) => { app.navigate(`/audits/edit`) },
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { app.navigate(`/audits/delete`) },
			icon: "pi pi-minus-circle"
		}
	]
		return (<Menubar className="p-0" model={items} />);
	}
	function PageFooter() {
		if (props.showFooter) {
			return (
				<div className="flex justify-content-between">
				</div>
			);
		}
	}
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	if(pageReady){
		return (
			<div>
<main className="main-page">
{ (props.showHeader) && 
<section className="page-section mb-3" >
    <div className="container">
        <div className="grid align-items-center">
            { !props.isSubPage && 
            <div className="col-fixed " >
                <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
            </div>
            }
            <div className="col " >
                <Title title="Ver"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-sm text-500"      separator={false} />
            </div>
        </div>
    </div>
</section>
}
<section className="page-section " >
    <div className="container">
        <div className="grid ">
            <div className="col comp-grid" >
                <div >
                    {/*PageComponentStart*/}
                    <div className="mb-3 grid justify-content-start">
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Log Id: </div>
                                <div className="col font-bold">{ item.log_id }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Action: </div>
                                <div className="col font-bold">{ item.action }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Page: </div>
                                <div className="col font-bold">{ item.page }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Record Id: </div>
                                <div className="col font-bold">{ item.record_id }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Old Values: </div>
                                <div className="col font-bold">{ item.old_values }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">New Values: </div>
                                <div className="col font-bold">{ item.new_values }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">User Id: </div>
                                <div className="col font-bold">{ item.user_id }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">User Ip: </div>
                                <div className="col font-bold">{ item.user_ip }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">User Agent: </div>
                                <div className="col font-bold">{ item.user_agent }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Request Url: </div>
                                <div className="col font-bold">{ item.request_url }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Timestamp: </div>
                                <div className="col font-bold">{item.timestamp && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(item.timestamp)} tooltip={utils.humanDatetime(item.timestamp)} />}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-content-between">
                        <div className="flex justify-content-start">
                            {ActionButton(item)}
                        </div>
                    </div>
                    {/*PageComponentEnd*/}
                </div>
            </div>
        </div>
    </div>
</section>
</main>
				<PageFooter />
			</div>
		);
	}
}
AuditsViewPage.defaultProps = {
	id: null,
	primaryKey: 'log_id',
	pageName: 'audits',
	apiPath: 'audits/view',
	routeName: 'auditsview',
	msgBeforeDelete: "¿Seguro que quieres borrar este registro?",
	msgTitle: 'Delete Record',
	msgAfterDelete: "Grabar eliminado con éxito",
	showHeader: true,
	showFooter: true,
	exportButton: true,
	isSubPage: false,
}
export default AuditsViewPage;
