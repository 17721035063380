/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/


export default function useMenus() {
    
    
    return {
	navbarTopRightItems: [],
	navbarTopLeftItems: [],
	navbarSideLeftItems: [
  {
    "to": "/home",
    "label": "Inicio",
    "icon": "pi pi-home text-primary",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/producto",
    "label": "Producto",
    "icon": "pi pi-tags text-primary",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/users",
    "label": "Settings",
    "icon": "pi pi-users text-primary",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/users",
        "label": "Users",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/roles",
        "label": "Roles",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/permissions",
        "label": "Permissions",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/audits",
        "label": "Audits",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
      }
    ]
  }
],
        exportFormats: {
            print: {
                label: 'Print',
                icon: 'pi pi-print',
                type: 'print',
                ext: '',
            },
            pdf: {
                label: 'Pdf',
                icon: 'pi pi-file-pdf',
                type: 'pdf',
                ext: 'pdf',
            },
            excel: {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                type: 'excel',
                ext: 'xlsx',
            },
            csv: {
                label: 'Csv',
                icon: 'pi pi-table',
                type: 'csv',
                ext: 'csv',
            },
        },
    }
}