import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const PermissionsAddPage = (props) => {
		const app = useApp();
	const validationSchema = yup.object().shape({
		permission: yup.string().required().label("Permission"),
		role_id: yup.number().nullable().label("Role Id")
	});
	const formDefaultValues = {
		permission: '', 
		role_id: '', 
	}
	// where the logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/permissions`);
		}
	}
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	if(pageReady){
		return (
<main className="main-page">
{ (props.showHeader) && 
<section className="page-section mb-3" >
    <div className="container">
        <div className="grid align-items-center">
            { !props.isSubPage && 
            <div className="col-fixed " >
                <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
            </div>
            }
            <div className="col " >
                <Title title="Agregar nuevo"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-sm text-500"      separator={false} />
            </div>
        </div>
    </div>
</section>
}
<section className="page-section " >
    <div className="container">
        <div className="grid ">
            <div className="md:col-9 sm:col-12 comp-grid" >
                <div >
                    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                        {(formik) => 
                        <>
                        <Form className={`${!props.isSubPage ? 'card ' : ''}`}>
                            <div className="grid">
                                <div className="col-12">
                                    <div className="formgrid grid">
                                        <div className="col-12 md:col-3">
                                            Permission *
                                        </div>
                                        <div className="col-12 md:col-9">
                                            <InputText name="permission"  onChange={formik.handleChange}  value={formik.values.permission}   label="Permission" type="text" placeholder="Escribir Permission"        className={inputClassName(formik?.errors?.permission)} />
                                            <ErrorMessage name="permission" component="span" className="p-error" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="formgrid grid">
                                        <div className="col-12 md:col-3">
                                            Role Id 
                                        </div>
                                        <div className="col-12 md:col-9">
                                            <DataSource   apiPath="components_data/role_id_option_list" >
                                                {
                                                ({ response  }) => 
                                                <>
                                                <Dropdown  name="role_id"     optionLabel="label" optionValue="value" value={formik.values.role_id} onChange={formik.handleChange} options={response} label="Role Id"  placeholder="Seleccione un valor"  className={inputClassName(formik?.errors?.role_id)}   />
                                                <ErrorMessage name="role_id" component="span" className="p-error" />
                                                </>
                                                }
                                            </DataSource>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { props.showFooter && 
                            <div className="text-center my-3">
                                <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Entregar" icon="pi pi-send" loading={saving} />
                            </div>
                            }
                        </Form>
                        </>
                        }
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </main>
		);
	}
}
PermissionsAddPage.defaultProps = {
	primaryKey: 'permission_id',
	pageName: 'permissions',
	apiPath: 'permissions/add',
	routeName: 'permissionsadd',
	submitButtonLabel: "Entregar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: 'Create Record',
	msgAfterSave: "Grabar agregado exitosamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default PermissionsAddPage;
