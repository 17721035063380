import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import useUtils from 'hooks/useUtils';

import useListPage from 'hooks/useListPage';
import useFilters from 'hooks/useFilters';
const AuditsListPage = (props) => {
		const auth = useAuth();
	const app = useApp();
	const utils = useUtils();
	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageFilterController = useFilters(filterSchema);
	const pageController = useListPage(props, pageFilterController);
	const { records, pageReady, loading, sortBy, sortOrder, apiRequestError, getPageBreadCrumbs, onSort, pagination } = pageController;
	const { filters, setFilterValue } = pageFilterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } =  pagination;
	function ActionButton(data){
		const items = [
		{
			label: "View",
			command: (event) => { app.navigate(`/audits/view/${data.log_id}`) },
			icon: "pi pi-eye",
			visible: () => auth.canView('audits/view')
		},
		{
			label: "Edit",
			command: (event) => { app.navigate(`/audits/edit`) },
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { app.navigate(`/audits/delete`) },
			icon: "pi pi-minus-circle"
		}
	]
	.filter((item) => {
		if(item.visible){
			return item.visible()
		}
		return true;
	});
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function LogIdTemplate(data){
		if(data){
			return (
				<Link to={`/audits/view/${data.log_id}`}> { data.log_id }</Link>
			);
		}
	}
	function TimestampTemplate(data){
		if(data){
			return (
				<>{data.timestamp && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(data.timestamp)} tooltip={utils.humanDatetime(data.timestamp)} />}</>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">Cargando...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					ningún record fue encontrado
				</div>
			);
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">Archivos <b>{ recordsPosition } de { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap justify-content-between">
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main className="main-page">
{ (props.showHeader) && 
<section className="page-section mb-3" >
    <div className="container-fluid">
        <div className="grid justify-content-between align-items-center">
            <div className="col " >
                <Title title="Audits"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-sm text-500"      separator={false} />
            </div>
            <div className="col-12 md:col-3 " >
                <Link to={`/audits/add`}>
                    <Button label="Agregar nuevo" icon="pi pi-plus" type="button" className="p-button w-full bg-primary "  />
                    </Link>
                </div>
                <div className="col-12 md:col-5 lg:col-4 " >
                    <span className="p-input-icon-left w-full">
                    <i className="pi pi-search" />
                    <InputText placeholder="Buscar" className="w-full" value={filters.search.value}  onChange={(e) => setFilterValue('search', e.target.value)} />
                    </span>
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container-fluid">
            <div className="grid ">
                <div className="col comp-grid" >
                    <FilterTags filterController={pageFilterController} />
                    <div >
                        <PageBreadcrumbs />
                        <div className="page-records">
                            <DataTable 
                                lazy={true} 
                                loading={loading} 
                                value={records} 
                                dataKey="log_id" 
                                sortField={sortBy} 
                                sortOrder={sortOrder} 
                                onSort={onSort} 
                                stripedRows
                                resizableColumns columnResizeMode="fit"
                                className=" p-datatable-sm" 
                                showGridlines={false} rowHover={true} 
                                responsiveLayout="stack" 
                                emptyMessage={<EmptyRecordMessage />} 
                                >
                                {/*PageComponentStart*/}
                                <Column  field="log_id" header="Log Id" body={LogIdTemplate}  ></Column>
                                <Column  field="action" header="Action"   ></Column>
                                <Column  field="page" header="Page"   ></Column>
                                <Column  field="record_id" header="Record Id"   ></Column>
                                <Column  field="old_values" header="Old Values"   ></Column>
                                <Column  field="new_values" header="New Values"   ></Column>
                                <Column  field="user_id" header="User Id"   ></Column>
                                <Column  field="user_ip" header="User Ip"   ></Column>
                                <Column  field="user_agent" header="User Agent"   ></Column>
                                <Column  field="request_url" header="Request Url"   ></Column>
                                <Column  field="timestamp" header="Timestamp" body={TimestampTemplate}  ></Column>
                                <Column headerStyle={{width: '3em'}} headerClass="text-center" body={ActionButton}></Column>
                                {/*PageComponentEnd*/}
                            </DataTable>
                        </div>
                        <PageLoading />
                        <PageFooter />
                    </div>
                </div>
            </div>
        </div>
    </section>
    </main>
	);
}
AuditsListPage.defaultProps = {
	primaryKey: 'log_id',
	pageName: 'audits',
	apiPath: 'audits/index',
	routeName: 'auditslist',
	msgBeforeDelete: "¿Seguro que quieres borrar este registro?",
	msgTitle: 'Delete Record',
	msgAfterDelete: "Grabar eliminado con éxito",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: false,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default AuditsListPage;
