import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { CheckDuplicate } from 'components/CheckDuplicate';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';

import useAddPage from 'hooks/useAddPage';
const RegisterPage = (props) => {
		const auth = useAuth();
	const app = useApp();
	const validationSchema = yup.object().shape({
		username: yup.string().required().label("Username"),
		userpassword: yup.string().required().label("Userpassword"),
		confirm_password: yup.string().required().label("Confirm Password").oneOf([yup.ref('userpassword')], "Your passwords do not match"),
		useremail: yup.string().email().required().label("Useremail")
	});
	const formDefaultValues = {
		username: '', 
		userpassword: '', 
		confirm_password: '', 
		useremail: '', 
	}
	// where the logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		const nextPage = response.nextpage || '/home';
		if (response.token) {
			auth.login(response.token, false);
			app.navigate(nextPage);
		}
		else{
			app.navigate(nextPage);
		}
	}
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	if(pageReady){
		return (
<main className="main-page">
{ (props.showHeader) && 
<section className="page-section mb-3 mb-3" >
    <div className="container">
        <div className="grid align-items-center">
            { !props.isSubPage && 
            <div className="col-fixed " >
                <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
            </div>
            }
            <div className=" col-12 md:col-6 " >
                <Title title="Registro de usuario"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-sm text-500"      separator={false} />
            </div>
            <div className="col-12 md:col-4 comp-grid" >
                <div className="">
                    <div className="flex align-items-center">
                        <div>¿Ya tienes una cuenta?</div>
                        <div className="ml-2">
                            <Link to="/">
                                <Button icon="pi pi-user" label="Iniciar sesión" /> 
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Username *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <CheckDuplicate value={formik.values.username} apiPath="components_data/users_username_exist">
                                                { (checker) => 
                                                <>
                                                <InputText name="username" onBlur={checker.check} onChange={formik.handleChange}  value={formik.values.username}   label="Username" type="text" placeholder="Escribir Username"        className={inputClassName(formik?.errors?.username)} />
                                                <ErrorMessage name="username" component="span" className="p-error" />
                                                {(!checker.loading && checker.exist) && <small className="p-error">No disponible</small>}
                                                {checker.loading && <small className="text-500">Checking...</small> }
                                                </>
                                                }
                                                </CheckDuplicate>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Userpassword *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Password name="userpassword" value={formik.values.userpassword} onChange={formik.handleChange} label="Userpassword" placeholder="Escribir Userpassword"  inputClassName="w-full" toggleMask feedback className={inputClassName(formik?.errors?.userpassword)} />
                                                <ErrorMessage name="userpassword" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Confirm Password *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Password name="confirm_password" id="confirm_password" className={inputClassName(formik?.errors?.comfirm_password)} inputClassName="w-full" feedback={false} toggleMask  value={formik.values.confirm_password} onChange={formik.handleChange} label="Confirmar contraseña" placeholder="Confirmar contraseña"  />
                                                <ErrorMessage name="confirm_password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Useremail *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <CheckDuplicate value={formik.values.useremail} apiPath="components_data/users_useremail_exist">
                                                { (checker) => 
                                                <>
                                                <InputText name="useremail" onBlur={checker.check} onChange={formik.handleChange}  value={formik.values.useremail}   label="Useremail" type="email" placeholder="Escribir Useremail"        className={inputClassName(formik?.errors?.useremail)} />
                                                <ErrorMessage name="useremail" component="span" className="p-error" />
                                                {(!checker.loading && checker.exist) && <small className="p-error">No disponible</small>}
                                                {checker.loading && <small className="text-500">Checking...</small> }
                                                </>
                                                }
                                                </CheckDuplicate>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Entregar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main>
		);
	}
}
RegisterPage.defaultProps = {
	primaryKey: 'userid',
	pageName: 'users',
	apiPath: 'auth/register',
	routeName: 'usersuserregister',
	submitButtonLabel: "Entregar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: 'Create Record',
	msgAfterSave: "Grabar agregado exitosamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default RegisterPage;
