import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import RolesViewPage from 'pages/roles/View';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import UsersEditPage from 'pages/users/Edit';
import useUtils from 'hooks/useUtils';

import useViewPage from 'hooks/useViewPage';
const UsersViewPage = (props) => {
		const auth = useAuth();
	const app = useApp();
	const utils = useUtils();
	const pageController = useViewPage(props);
	const { item, pageReady, loading, apiRequestError, deleteItem } = pageController;
	function ActionButton(data){
		const items = [
		{
			label: "Edit",
			command: (event) => { app.openPageDialog(<UsersEditPage isSubPage apiPath={`/users/edit/${data.userid}`} />, {closeBtn: true }) },
			icon: "pi pi-pencil",
			visible: () => auth.canView('users/edit')
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.userid) },
			icon: "pi pi-minus-circle",
			visible: () => auth.canView('users/delete')
		}
	]
	.filter((item) => {
		if(item.visible){
			return item.visible()
		}
		return true;
	});
		return (<Menubar className="p-0" model={items} />);
	}
	function PageFooter() {
		if (props.showFooter) {
			return (
				<div className="flex justify-content-between">
				</div>
			);
		}
	}
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	if(pageReady){
		return (
			<div>
<main className="main-page">
{ (props.showHeader) && 
<section className="page-section mb-3" >
    <div className="container">
        <div className="grid align-items-center">
            { !props.isSubPage && 
            <div className="col-fixed " >
                <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
            </div>
            }
            <div className="col " >
                <Title title="Ver"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-sm text-500"      separator={false} />
            </div>
        </div>
    </div>
</section>
}
<section className="page-section " >
    <div className="container">
        <div className="grid ">
            <div className="col comp-grid" >
                <div >
                    {/*PageComponentStart*/}
                    <div className="mb-3 grid justify-content-start">
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Userid: </div>
                                <div className="col font-bold">{ item.userid }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Username: </div>
                                <div className="col font-bold">{ item.username }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Useremail: </div>
                                <div className="col font-bold">{ item.useremail }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">User Role Id: </div>
                                <div className="col font-bold">{item.user_role_id && <Button className="p-button-text" icon="pi pi-eye" label="Roles Detail" onClick={() => app.openPageDialog(<RolesViewPage isSubPage apiPath={`/roles/view/${item.user_role_id}`} />, {closeBtn: true })} /> }</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Date Created: </div>
                                <div className="col font-bold">{item.date_created && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(item.date_created)} tooltip={utils.humanDatetime(item.date_created)} />}</div>
                            </div>
                        </div>
                        <div className="col-fixed">
                            <div className="grid align-items-center card surface-50 p-3">
                                <div className="col-fixed  text-500 font-medium">Date Updated: </div>
                                <div className="col font-bold">{item.date_updated && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(item.date_updated)} tooltip={utils.humanDatetime(item.date_updated)} />}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-content-between">
                        <div className="flex justify-content-start">
                            {ActionButton(item)}
                        </div>
                    </div>
                    {/*PageComponentEnd*/}
                </div>
            </div>
        </div>
    </div>
</section>
</main>
				<PageFooter />
			</div>
		);
	}
}
UsersViewPage.defaultProps = {
	id: null,
	primaryKey: 'userid',
	pageName: 'users',
	apiPath: 'users/view',
	routeName: 'usersview',
	msgBeforeDelete: "¿Seguro que quieres borrar este registro?",
	msgTitle: 'Delete Record',
	msgAfterDelete: "Grabar eliminado con éxito",
	showHeader: true,
	showFooter: true,
	exportButton: true,
	isSubPage: false,
}
export default UsersViewPage;
