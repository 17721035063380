import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const ProductoAddPage = (props) => {
		const app = useApp();
	const validationSchema = yup.object().shape({
		prodname: yup.string().nullable().label("Prodname"),
		prodprice: yup.number().nullable().label("Prodprice"),
		prodstock: yup.number().nullable().label("Prodstock"),
		prodstatus: yup.number().nullable().label("Prodstatus")
	});
	const formDefaultValues = {
		prodname: '', 
		prodprice: '', 
		prodstock: '', 
		prodstatus: '', 
	}
	// where the logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/producto`);
		}
	}
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	if(pageReady){
		return (
<main className="main-page">
{ (props.showHeader) && 
<section className="page-section mb-3" >
    <div className="container">
        <div className="grid align-items-center">
            { !props.isSubPage && 
            <div className="col-fixed " >
                <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
            </div>
            }
            <div className="col " >
                <Title title="Agregar nuevo"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-sm text-500"      separator={false} />
            </div>
        </div>
    </div>
</section>
}
<section className="page-section " >
    <div className="container">
        <div className="grid ">
            <div className="md:col-9 sm:col-12 comp-grid" >
                <div >
                    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                        {(formik) => 
                        <>
                        <Form className={`${!props.isSubPage ? 'card ' : ''}`}>
                            <div className="grid">
                                <div className="col-12">
                                    <div className="formgrid grid">
                                        <div className="col-12 md:col-3">
                                            Prodname 
                                        </div>
                                        <div className="col-12 md:col-9">
                                            <InputText name="prodname"  onChange={formik.handleChange}  value={formik.values.prodname}   label="Prodname" type="text" placeholder="Escribir Prodname"        className={inputClassName(formik?.errors?.prodname)} />
                                            <ErrorMessage name="prodname" component="span" className="p-error" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="formgrid grid">
                                        <div className="col-12 md:col-3">
                                            Prodprice 
                                        </div>
                                        <div className="col-12 md:col-9">
                                            <InputText name="prodprice"  onChange={formik.handleChange}  value={formik.values.prodprice}   label="Prodprice" type="number" placeholder="Escribir Prodprice"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.prodprice)} />
                                            <ErrorMessage name="prodprice" component="span" className="p-error" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="formgrid grid">
                                        <div className="col-12 md:col-3">
                                            Prodstock 
                                        </div>
                                        <div className="col-12 md:col-9">
                                            <InputText name="prodstock"  onChange={formik.handleChange}  value={formik.values.prodstock}   label="Prodstock" type="number" placeholder="Escribir Prodstock"  min={0}  step="any"    className={inputClassName(formik?.errors?.prodstock)} />
                                            <ErrorMessage name="prodstock" component="span" className="p-error" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="formgrid grid">
                                        <div className="col-12 md:col-3">
                                            Prodstatus 
                                        </div>
                                        <div className="col-12 md:col-9">
                                            <InputText name="prodstatus"  onChange={formik.handleChange}  value={formik.values.prodstatus}   label="Prodstatus" type="number" placeholder="Escribir Prodstatus"  min={0}  step="any"    className={inputClassName(formik?.errors?.prodstatus)} />
                                            <ErrorMessage name="prodstatus" component="span" className="p-error" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { props.showFooter && 
                            <div className="text-center my-3">
                                <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Entregar" icon="pi pi-send" loading={saving} />
                            </div>
                            }
                        </Form>
                        </>
                        }
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </main>
		);
	}
}
ProductoAddPage.defaultProps = {
	primaryKey: 'prodid',
	pageName: 'producto',
	apiPath: 'producto/add',
	routeName: 'productoadd',
	submitButtonLabel: "Entregar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: 'Create Record',
	msgAfterSave: "Grabar agregado exitosamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ProductoAddPage;
