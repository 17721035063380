import { Routes, Route, Outlet } from 'react-router-dom';
import List from './List';
import View from './View';
import Add from './Add';
import Edit from './Edit';
export default function Permissions(props) {
	return (
		<>
			<Outlet />
			<Routes>
				<Route path="" element={<List />} />
				<Route path=":fieldName/:fieldValue" element={<List />} />
				<Route path="index/:fieldName/:fieldValue" element={<List />} />
				<Route path="view/:pageid" element={<View />} />
				<Route path="add" element={<Add />} />
				<Route path="edit/:pageid" element={<Edit />} />
			</Routes>
		</>
	);
}
