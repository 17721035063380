import { Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Avatar } from 'primereact/avatar';
import { TabMenu } from 'primereact/tabmenu';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';

import AccountViewPage from './Accountview';
import AccountEditPage from './Accountedit';
import ChangePassword from './ChangePassword';
export default function Account(props) {
		const auth = useAuth();
	const app = useApp();
	const location = useLocation();
	let pageIndex = 0;
	const pathName = window.location.pathname || '';
	const pageName = pathName.split('/')[2] || '';
	if(pageName === 'edit'){
		pageIndex = 1;
	}
	else if(pageName === 'changepassword'){
		pageIndex = 2;
	}
	const [activeIndex, setActiveIndex] = useState(pageIndex);
	const accountMenuItems = [
		{
			label: "Detalle de cuenta", 
			icon: 'pi pi-fw pi-user', 
			command: () => app.navigate('/account')
		},
		{
			label:  "Editar cuenta", 
			icon: 'pi pi-fw pi-user-edit', 
			command: () => app.navigate('/account/edit')
		},
		{
			label: "Cambia la contraseña", 
			icon: 'pi pi-fw pi-key', 
			command: () => app.navigate('/account/changepassword')
		},
	];
	return (
		<div style={{minHeight:'100px'}}>
			<div className="surface-100 card p-0 mb-4">
	<div className="p-4 mb-4">
		<div className="grid align-items-center">
			<div className="col-fixed">
				<Avatar icon="pi pi-user" size="xlarge" shape="circle" />
			</div>
			<div className="col">
				<div className="text-2xl text-primary"> { auth.userName } </div>
				<div className="text-sm text-gray-500"> { auth.userEmail } </div>
			</div>
		</div>
	</div>
			</div>
			<div className="card p-2">
				<TabMenu model={accountMenuItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
				<div className="grid justify-content-between my-3">
					<div className="col">
						<Outlet />
						<Routes>
							<Route path="/" element={<AccountViewPage/>} />
<Route path="/edit" element={<AccountEditPage/>} />
<Route path="/changepassword" element={<ChangePassword/>} />
						</Routes>
					</div>
				</div>
			</div>
		</div>
	);
}
